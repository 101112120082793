import React from "react"

const HomeAddress = (props) => (
    <>
        <p className="address">
            <span className="fullName">Jan Wessel</span>
            <span className="streetName">Paderborner Str. 111</span>
            <span className="location">32760 Detmold</span>
        </p>
    </>
);

export default HomeAddress