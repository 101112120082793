import React from 'react'

import { Link } from "gatsby"

import SEO from "../components/seo"

import Legal from "../layouts/legal"
import Main from "../components/main"
import PageHeader from '../components/chrome/pageHeader'
import PageFooter from '../components/chrome/pageFooter'
import Container from "../layouts/container"
import HomeAddress from '../components/snippets/homeAddress'
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageLegal = () => (
    <Legal>
        <SEO title="Legal notice" />
        <PageHeader headerText="Legal Notice" />

        <Main>
            <Container>
                <Breadcrumbs title="Legal notice" path="/legal_notice" />
            </Container>
            <div className="row">
                <div className="col--16">
                    <Container>
                        <h1 className="h1">Legal notice</h1>

                        <h2 className="h2">Statement (§ 5 TMG)</h2>

                        <HomeAddress />

                        <h2 className="h2">Contact information</h2>
                        <p>
                            Phone: +49 151 – 70856078<br />
                            E-Mail: info@mood-design.net
                        </p>

                        <h2 className="h2">Responsible for the content (§ 55 Abs. 2 RStV)</h2>
                        <HomeAddress />

                        <h2 className="h2">Website terms and conditions of use</h2>
                        <ol className="terms">
                            <li className="terms__paragraph">
                                <h3 className="h3">1. Terms</h3>
                                <p>
                                    By accessing this website, accessible from mood-design.net (in the following referred to as "we", "us" or "the author"), you are agreeing to be bound by these website terms and conditions of use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this website are protected by copyright and trade mark law. This document has been created with the help of the <a rel="noopener noreferrer" target="_blank" href="https://www.termsofservicegenerator.net">Terms of Service Generator</a> and the <a rel="noopener noreferrer" target="_blank" href="https://www.privacy-policy-template.com">Privacy Policy Template</a> and modified thereafter.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">
                                    2. Use license and copyright
                                </h3>
                                <p>
                                    The content and works created by the author on these pages are liable to german copyright.
                                </p>
                                <p>
                                    If any content on these pages has not been created by the author, third-party copyrights are respected. If applicable, external contents are marked as such. Should you find any case of copyright violation, please inform the author. Should the author find any unlicensed copyrighted material on these pages, the materials will be removed as soon as possible.
                                </p>
                                <p>
                                    Permission is granted to temporarily download one copy of the materials on this website for personal,   non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                                </p>
                                <ul className="unordered">
                                    <li className="unordered__entry">modify or copy the materials;</li>
                                    <li className="unordered__entry">use the materials for any commercial purpose or for any public display;</li>
                                    <li className="unordered__entry">attempt to reverse engineer any software contained on this website;</li>
                                    <li className="unordered__entry">remove any copyright or other proprietary notations from the materials; or</li>
                                    <li className="unordered__entry">transferring the materials to another person or "mirror" the materials on any other server.</li>
                                </ul>
                                <p>
                                    This will let us to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">3. Disclaimer</h3>
                                <p>
                                    All the materials on this website are provided "as is". We make no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, we do not make any representations concerning the accuracy or reliability of the use of the materials on its website or otherwise relating to such materials or any sites linked to this website.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">4. Limitations</h3>
                                <p>
                                    The author or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on this website, even if mdefdef or an authorized representative of this website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">5. Revisions and Errata</h3>
                                <p>
                                    The materials appearing on this website may include technical, typographical, or photographic errors. The author will not promise that any of the materials in this website are accurate, complete, or current. The author may change the materials contained on its website at any time without notice. We do not make any commitment to update the materials.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">6. Links</h3>
                                <p>
                                    The author has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by us of the site. The use of any linked website is at the user’s own risk.
                                </p>
                            </li>
                            <li className="terms__paragraph">
                                <h3 className="h3">7. Site Terms of Use Modifications</h3>
                                <p>
                                    We may revise these Terms of Use for its website at any time without prior notice. By using this website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">8. Your Privacy</h3>
                                <p>
                                    Please read our <Link to="/privacy_statement">Privacy policy</Link>.
                                </p>
                            </li>

                            <li className="terms__paragraph">
                                <h3 className="h3">9. Governing Law</h3>
                                <p>
                                    Any claim related to this website shall be governed by the laws of Germany without regards to its conflict of law provisions.
                                </p>
                            </li>
                        </ol>
                    </Container>
                </div>
            </div>
        </Main>

        <PageFooter />
    </Legal>
);

export default PageLegal
